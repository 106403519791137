<template>
  <page-layout>
    <v-card>
      <v-card-title> Yarım Kalan Sınavlar </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        class="elevation-1 mt-3"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Silmek İstediğinizden Emin Misiniz ?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="closeDelete"
                    >İptal Et</v-btn
                  >
                  <v-btn color="red darken-1" text @click="deleteItemConfirm"
                    >Evet</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-icon v-if="item.status === 'abandon'" small color="red">
            mdi-brightness-1
          </v-icon>
          <v-icon v-if="item.status === 'start'" small color="orange">
            mdi-brightness-1
          </v-icon>
          <v-icon v-if="item.status === 'process'" small color="yellow">
            mdi-brightness-1
          </v-icon>
          <v-icon v-if="item.status === 'done'" small color="green">
            mdi-brightness-1
          </v-icon>
        </template>
        <template v-slot:[`item.answers`]="{ item }">
          {{ calcResolvedTest(item) }}
        </template>
        <template v-slot:[`item._id`]="{ item }">
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </page-layout>
</template>

<script>
import axios from '@axios';
import dateformat from 'dateformat';
import PageLayout from '@/components/PageLayout';

export default {
  name: 'CandidateAction',
  components: {
    PageLayout
  },
  data: () => ({
    isLoading: true,
    dialogDelete: false,
    selectedDeleteItemId: '',
    items: [],
    headers: [
      {
        text: 'T.C.',
        value: 'candidateId.idNumber',
        sortable: false
      },
      {
        text: 'İsim',
        value: 'candidateId.name',
        sortable: false
      },
      {
        text: 'Soyisim',
        value: 'candidateId.lastname',
        sortable: false
      },
      {
        text: 'Telefon',
        value: 'candidateId.phone',
        sortable: false
      },
      {
        text: 'Email',
        value: 'candidateId.email',
        sortable: false
      },
      {
        text: 'Sınav Adı',
        value: 'examId.name',
        sortable: false
      },
      {
        text: 'Test Adı',
        value: 'testId.name',
        sortable: false
      },
      {
        text: 'Başlangıç',
        value: 'createdAt',
        sortable: false
      },
      {
        text: 'Durum',
        value: 'status',
        sortable: false
      },
      {
        text: 'Oran',
        value: 'answers',
        sortable: false
      },
      {
        width: '100px',
        text: 'İşlemler',
        value: '_id',
        sortable: false
      }
    ]
  }),
  mounted() {
    this.list();
  },
  methods: {
    calcResolvedTest: function (item) {
      if (item.answers.length == 0) {
        return '% 0';
      }
      return `% ${(100 * item.answers.length) / item.testId.questions.length}`;
    },
    formatDate: function (val) {
      return dateformat(new Date(val), 'yyyy-mm-dd HH:MM:ss');
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.selectedDeleteItemId = item._id;
    },
    deleteItemConfirm() {
      this.closeDelete();
      axios
        .get(`/delete-candidate-action/${this.selectedDeleteItemId}`)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.warning('Aday Silindi.');
            this.list();
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    list() {
      axios
        .get('/list-unsuccess-candidate-action')
        .then((response) => {
          if (response?.data?.result) {
            this.items = response?.data?.result;
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.$toast.error('Error while getting test list');
        });
    }
  }
};
</script>
